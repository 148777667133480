import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import Icons from "./icons";

const Footer = () => {
  const navigate = useNavigate();

  // %%%%%%%%%%%%%%%%%%%%[ Tooltip ]%%%%%%%%%%%%%%%%%%%%
  useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <footer>
      <div className="copyright-container">
        <p>
          <span className="reactIcon">{Icons.copyright}</span>2021 -{" "}
          {new Date().getFullYear()} All Rights Reserved. Powered by{" "}
          <span
            data-tip="Ahmed Farhat"
            data-place="top"
            data-effect="solid"
            data-for="tooltipControl"
            onClick={() => navigate("/")}
            className="myUsername"
          >
            ROOT
          </span>
          <span className="reactIcon heart">{Icons.heart}</span>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
