import { useNavigate } from "react-router-dom";
import Images from "../images";
import "../../assets/css/notFound.css";

const NotFound = ({ theme }) => {
  const navigate = useNavigate();

  return (
    <section className="errorCode">
      <>
        <div className="notFound unselectable">
          <div className="smile">
            <img
              src={
                theme === "light"
                  ? Images.darkSmileImage
                  : Images.lightSmileImage
              }
              alt="Error Code"
              id="errorImage"
            />
          </div>
          <div className="errorInfos">
            <h1>oops!</h1>
            <h2>Error 404: Page Not Found</h2>
            <p>The resource requested could not be found on this server.</p>
            <div className="backBtn">
              <button onClick={() => navigate(-1)}>Go Back</button>
            </div>
          </div>
        </div>
      </>
    </section>
  );
};

export default NotFound;
