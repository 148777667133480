import { useEffect } from "react";
import Icons from "./icons";

const ScrollUp = () => {
  useEffect(() => {
    // %%%%%%%%%%%%%%%%%%%%[ Detect Scroll Range ]%%%%%%%%%%%%%%%%%%%%
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        document.querySelector(".goTopBtn").style.opacity = 0.8;
        document.querySelector(".goTopBtn").style.pointerEvents = "auto";
      } else {
        document.querySelector(".goTopBtn").style.opacity = 0;
        document.querySelector(".goTopBtn").style.pointerEvents = "none";
      }
    });
  }, []);

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="goTopBtn" onClick={scrollTop}>
      <span className="reactIcon">{Icons.topArrow}</span>
    </div>
  );
};

export default ScrollUp;
