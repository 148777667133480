import { useEffect } from "react";
import ReactTooltip from "react-tooltip";
import Icons from "../icons";
import Images from "../images";
import "../../assets/css/contact.css";

const Contact = () => {
  useEffect(() => {
    // %%%%%%%%%%%%%%%%%%%%[ Hide Menu List on Route Change ]%%%%%%%%%%%%%%%%%%%%
    document.getElementById("menu-list").style.maxHeight = "0px";

    // %%%%%%%%%%%%%%%%%%%%[ Hide Copy Tooltip ]%%%%%%%%%%%%%%%%%%%%
    document.querySelector("#copyBtn").addEventListener("click", () => {
      setTimeout(() => {
        ReactTooltip.hide();
      }, 1000);
      navigator.clipboard.writeText("contact@ahmedfarhat.com");
    });
  }, []);

  useEffect(() => {
    // %%%%%%%%%%%%%%%%%%%%[ Tooltip ]%%%%%%%%%%%%%%%%%%%%
    ReactTooltip.rebuild();
  });

  return (
    <section className="contact">
      <div className="showcase-content">
        <div className="infos-container">
          <h1>Get in touch !</h1>
          <p className="description">
            I am always open to discussing new projects, creative ideas or
            opportunities to be part of your visions.
          </p>
          <div className="my-link">
            <div className="directly unselectable">
              <p className="email">
                <span className="reactIcon">{Icons.envelope}</span>
                <span className="unselectable">mail me</span>
                contact@ahmedfarhat.com
                <span
                  className="reactIcon copy"
                  data-tip="Copied"
                  data-place="top"
                  data-effect="solid"
                  data-for="tooltipControl"
                  data-event="click"
                  id="copyBtn"
                  data-type="success"
                >
                  {Icons.copy}
                </span>
              </p>
              <br />
              <p className="call">
                <span className="reactIcon">{Icons.phone}</span>
                <span className="unselectable">call me</span>+216 54 436 840
              </p>
              <br />
              <p className="location">
                <span className="reactIcon">{Icons.location}</span>
                <span className="unselectable">locate me</span>Sousse, Tunisia
              </p>
            </div>
            <div className="socialNetworks-container unselectable">
              <div className="social-business">
                <a
                  href="https://www.linkedin.com/in/ahmedfarhat99/"
                  target="_blank"
                  rel="noreferrer noopener"
                  data-tip="LinkedIn"
                  data-place="top"
                  data-effect="float"
                  data-for="tooltipControl"
                >
                  <img src={Images.linkedin} alt="LinkedIn" />
                </a>
                <a
                  href="https://github.com/ahmedfarhat99"
                  target="_blank"
                  rel="noreferrer noopener"
                  data-tip="GitHub"
                  data-place="top"
                  data-effect="float"
                  data-for="tooltipControl"
                >
                  <img src={Images.githubb} alt="GitHub" />
                </a>
                <a
                  href="https://alpha.ahmedfarhat.com"
                  target="_blank"
                  rel="noreferrer noopener"
                  data-tip="Discord Server"
                  data-place="top"
                  data-effect="float"
                  data-for="tooltipControl"
                >
                  <img src={Images.discord} alt="Discord" />
                </a>
                <a
                  href="https://www.buymeacoffee.com/ahmedfarhat"
                  target="_blank"
                  rel="noreferrer noopener"
                  data-tip="Buy Me a Coffee"
                  data-place="top"
                  data-effect="float"
                  data-for="tooltipControl"
                >
                  <img src={Images.buyMeaCoffee} alt="Buy Me a Coffee" />
                </a>
                <a
                  href="https://ba9chich.com/ahmedfarhat"
                  target="_blank"
                  rel="noreferrer noopener"
                  data-tip="Bakchich"
                  data-place="top"
                  data-effect="float"
                  data-for="tooltipControl"
                >
                  <img src={Images.bakchich} alt="Bakchich" />
                </a>
                <a
                  href="https://www.paypal.com/donate/?business=3BC9MMGPLDC3E&no_recurring=0&currency_code=USD"
                  target="_blank"
                  rel="noreferrer noopener"
                  data-tip="PayPal"
                  data-place="top"
                  data-effect="float"
                  data-for="tooltipControl"
                >
                  <img src={Images.paypal} alt="PayPal" />
                </a>
              </div>
              {/* <div className="social-media">
                <a
                  href="https://wa.me/21654436840"
                  target="_blank"
                  rel="noreferrer noopener"
                  data-tip="WhatsApp"
                  data-place="top"
                  data-effect="float"
                  data-for="tooltipControl"
                >
                  <img src={Images.whatsapp} alt="WhatsApp" />
                </a>
              </div> */}
            </div>
          </div>
        </div>
        <div className="chat-container unselectable">
          <form
            action="https://formsubmit.co/contact@ahmedfarhat.com"
            method="POST"
          >
            <input type="hidden" name="_next" value="https://ahmedfarhat.com" />
            <input
              type="hidden"
              name="_subject"
              value="Portfolio Contact Submission"
            />
            <input
              name="Name"
              type="text"
              placeholder="Your Name"
              autoComplete="off"
              required
              title=""
            />{" "}
            <input
              name="Email"
              type="email"
              placeholder="Your Email"
              autoComplete="off"
              required
              title=""
            />{" "}
            <input
              name="Subject"
              type="text"
              placeholder="Your Subject"
              autoComplete="off"
              required
              title=""
            />
            <textarea
              name="Message"
              placeholder="Your Message"
              autoComplete="off"
              required
              title=""
            />
            <br />
            <button type="submit" className="submit-btn">
              Send
              <span className="reactIcon">{Icons.paperPlane}</span>
            </button>
            <div className="form-alert">
              <span className="output"></span>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Contact;
