import { BsMoon, BsBriefcaseFill, BsEnvelopeFill } from "react-icons/bs";
import { FaGraduationCap, FaPaperPlane, FaHeart } from "react-icons/fa";
import {
  IoMdArrowRoundUp,
  IoIosArrowDown,
  IoIosArrowUp,
  IoIosCloseCircleOutline,
} from "react-icons/io";
import { MdLocationPin, MdPhone, MdOutlineContentCopy } from "react-icons/md";
import { FiDownload, FiSun } from "react-icons/fi";
import { BiUser, BiCopyright } from "react-icons/bi";
import { HiMenu } from "react-icons/hi";
import { TiArrowSortedDown } from "react-icons/ti";

const Icons = {
  menu: <HiMenu />,
  moon: <BsMoon />,
  sun: <FiSun />,
  topArrow: <IoMdArrowRoundUp />,
  copyright: <BiCopyright />,
  heart: <FaHeart />,
  download: <FiDownload />,
  user: <BiUser />,
  arrowUp: <IoIosArrowUp />,
  arrowDown: <IoIosArrowDown />,
  graduation: <FaGraduationCap />,
  briefcase: <BsBriefcaseFill />,
  downArrow: <TiArrowSortedDown />,
  copy: <MdOutlineContentCopy />,
  envelope: <BsEnvelopeFill />,
  location: <MdLocationPin />,
  phone: <MdPhone />,
  paperPlane: <FaPaperPlane />,
  close: <IoIosCloseCircleOutline />,
};

export default Icons;
