import { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import { Lightbox } from "react-modal-image";
import Images from "../images";
import "../../assets/css/portfolio.css";
import projectsData from "../../data/projects.json";
// import LazyImage from "../LazyImage";

const Portfolio = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const reversedProjectsData = [...projectsData].reverse();

  // %%%%%%%%%%%%%%%%%%%%[ Hide Menu List on Route Change ]%%%%%%%%%%%%%%%%%%%%
  useEffect(() => {
    document.getElementById("menu-list").style.maxHeight = "0px";
  }, []);

  // %%%%%%%%%%%%%%%%%%%%[ Tooltip ]%%%%%%%%%%%%%%%%%%%%
  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const Project = ({
    image,
    name,
    category,
    description,
    keywords,
    demo,
    isVisible,
  }) => {
    const [openInFullScreen, setOpenInFullScreen] = useState(false);

    // %%%%%%%%%%%%%%%%%%%%[ Hide Scroll Bar when an image is in full screen ]%%%%%%%%%%%%%%%%%%%%
    useEffect(() => {
      if (openInFullScreen) document.body.style.overflow = "hidden";
      else document.body.style.overflow = "auto";
    }, [openInFullScreen]);

    return (
      <div className={`demo fade ${isVisible ? "" : "hidden"}`}>
        <div className="thumbnail" onClick={() => setOpenInFullScreen(true)}>
          <img src={image} alt={name} />
          {/* <LazyImage src={image} alt={name} /> */}
        </div>
        {openInFullScreen && (
          <Lightbox
            large={image}
            alt={name}
            onClose={() => setOpenInFullScreen(false)}
            hideDownload={true}
          />
        )}
        <div className="introduction">
          <h3 className="name">{name}</h3>
          <span className="category">{category}</span>
          <p className="description">{description}</p>
          <div className="key-words">
            {keywords.map((keyword, index) => (
              <span key={index}>{keyword}</span>
            ))}
          </div>
          {demo && (
            <a
              href={demo}
              target="_blank"
              rel="noopener noreferrer"
              className="demo-btn"
            >
              <span className="circle" aria-hidden="true">
                <span className="icon arrow"></span>
              </span>
              <span className="button-text unselectable">Preview</span>
            </a>
          )}
        </div>
      </div>
    );
  };

  // %%%%%%%%%%%%%%%%%%%%[ Navigate through slides every 5 seconds ]%%%%%%%%%%%%%%%%%%%%
  // useEffect(() => {
  //   const totalSlides = reversedProjectsData.length;

  //   const showSlides = (index) => {
  //     if (index < 0) {
  //       setCurrentSlide(totalSlides - 1);
  //     } else if (index >= totalSlides) {
  //       setCurrentSlide(0);
  //     } else {
  //       setCurrentSlide(index);
  //     }
  //   };

  // const interval = setInterval(() => {
  //   showSlides(currentSlide + 1);
  // }, 5000);

  // return () => clearInterval(interval);
  // }, [currentSlide]);

  return (
    <section className="portfolio">
      <div className="showcase-content">
        <div className="projects">
          <div className="title">
            <h2>Projects</h2>
          </div>
          <div className="container">
            {reversedProjectsData.map((project, index) => (
              <Project
                key={index}
                isVisible={index === currentSlide}
                {...project}
              />
            ))}
            <span
              className="prev unselectable"
              onClick={() =>
                setCurrentSlide(
                  currentSlide === 0
                    ? reversedProjectsData.length - 1
                    : currentSlide - 1
                )
              }
            >
              &#10094;
            </span>
            <span
              className="next unselectable"
              onClick={() =>
                setCurrentSlide(
                  currentSlide === reversedProjectsData.length - 1
                    ? 0
                    : currentSlide + 1
                )
              }
            >
              &#10095;
            </span>
            <div className="bullets-indicators unselectable">
              {reversedProjectsData.map((_, index) => (
                <span
                  key={index}
                  className={`dot ${index === currentSlide ? "active" : ""}`}
                  onClick={() => setCurrentSlide(index)}
                ></span>
              ))}
            </div>
          </div>
        </div>
        <hr className="portfolioSeparator" />
        <div className="certificates">
          <div className="title">
            <h2>Certificates</h2>
          </div>
          <div className="container">
            <div className="certificate">
              <img
                src={Images.starredCertificate}
                alt="Certificate"
                className="icon"
              />
              <span className="date unselectable">February 11, 2023</span>
              <span className="name">
                Microsoft Certified: Azure Fundamentals
              </span>
            </div>
            <div className="certificate">
              <img
                src={Images.certificate}
                alt="Certificate"
                className="icon"
              />
              <span className="date unselectable">February 3-5, 2023</span>
              <span className="name">
                Certificate of Participation: “Global Game Jam Sousse”
              </span>
            </div>
            <div className="certificate">
              <img
                src={Images.starredCertificate}
                alt="Certificate"
                className="icon"
              />
              <span className="date unselectable">April 29, 2022</span>
              <span className="name">
                CCNA: Switching, Routing, and Wireless Essentials
              </span>
            </div>
            <div className="certificate">
              <img
                src={Images.certificate}
                alt="Certificate"
                className="icon"
              />
              <span className="date unselectable">December 2,3, 2021</span>
              <span className="name">
                Certificate of Participation: “Nuit de l'Info” Competition
              </span>
            </div>
            <div className="certificate">
              <img
                src={Images.starredCertificate}
                alt="Certificate"
                className="icon"
              />
              <span className="date unselectable">January 21, 2021</span>
              <span className="name">
                Scrum Foundation Professional Certificate - SFPC
              </span>
            </div>
            <div className="certificate">
              <img
                src={Images.certificate}
                alt="Certificate"
                className="icon"
              />
              <span className="date unselectable">2019 - 2020</span>
              <span className="name">Certificate of Events Organization</span>
            </div>
            <div className="certificate">
              <img
                src={Images.certificate}
                alt="Certificate"
                className="icon"
              />
              <span className="date unselectable">February 19, 2020</span>
              <span className="name">
                Certificate of Organization: “MAGIC 3D” Workshop
              </span>
            </div>
            <div className="certificate">
              <img
                src={Images.certificate}
                alt="Certificate"
                className="icon"
              />
              <span className="date unselectable">January 15, 2020</span>
              <span className="name">Training Certificate: Photoshop</span>
            </div>
            <div className="certificate">
              <img
                src={Images.certificate}
                alt="Certificate"
                className="icon"
              />
              <span className="date unselectable">July 16, 2019</span>
              <span className="name">
                Professional Training Certificate: “GSM-SMARTPHONES” Maintenance
              </span>
            </div>
            <div className="certificate">
              <img
                src={Images.certificate}
                alt="Certificate"
                className="icon"
              />
              <span className="date unselectable">May 2, 2019</span>
              <span className="name">
                Training Certificate: Ionic 4 & Angular 2
              </span>
            </div>
            <div className="certificate">
              <img
                src={Images.certificate}
                alt="Certificate"
                className="icon"
              />
              <span className="date unselectable">February 11, 2019</span>
              <span className="name">Success Certificate: Python</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
