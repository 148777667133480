import { useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import Icons from "./icons";
import Images from "./images";

const Header = ({ setTheme, theme }) => {
  useEffect(() => {
    // %%%%%%%%%%%%%%%%%%%%[ Theme Mode ]%%%%%%%%%%%%%%%%%%%%
    let themeIcon = document.getElementById("darkTheme-icon");
    let myLogo = document.querySelector(".myLogo");
    if (localStorage.getItem("mode") == null) {
      localStorage.setItem("mode", "light");
    }

    let localData = localStorage.getItem("mode");
    if (localData === "light") {
      document.body.classList.remove("darkTheme");
      myLogo.src = Images.darkLogo;
      setTheme("light");
    } else if (localData === "dark") {
      document.body.classList.add("darkTheme");
      myLogo.src = Images.lightLogo;
      setTheme("dark");
    }

    themeIcon.addEventListener("click", () => {
      document.body.classList.toggle("darkTheme");
      if (document.body.classList.contains("darkTheme")) {
        myLogo.src = Images.lightLogo;
        localStorage.setItem("mode", "dark");
        setTheme("dark");
      } else {
        myLogo.src = Images.darkLogo;
        localStorage.setItem("mode", "light");
        setTheme("light");
      }
    });

    // %%%%%%%%%%%%%%%%%%%%[ Navbar Menu ]%%%%%%%%%%%%%%%%%%%%
    let menuList = document.getElementById("menu-list");
    let hamburgerMenu = document.getElementById("menu-icon");
    menuList.style.maxHeight = "0px";
    hamburgerMenu.addEventListener("click", () => {
      if (menuList.style.maxHeight === "0px") {
        menuList.style.maxHeight = "160px";
      } else {
        menuList.style.maxHeight = "0px";
      }
    });

    window.addEventListener("click", function (e) {
      if (!menuList.contains(e.target) && !hamburgerMenu.contains(e.target)) {
        menuList.style.maxHeight = "0px";
      }
    });

    // const navLinks = ["/about", "/portfolio", "/contact"];
    // let urlPath = window.location.pathname;

    // if (!navLinks.includes(urlPath)) {
    //   document.getElementById("homepage").className = "active";
    // } else {
    //   document.getElementById("homepage").className = "inactive";
    // }
  }, [setTheme]);

  // const unhighlightHomepage = () => {
  //   document.getElementById("homepage").className = "inactive";
  // };

  return (
    <header className="unselectable">
      <div className="navbar-container">
        <div className="navbar">
          <Link to="/" className="logo-container">
            <img src={Images.darkLogo} alt="ROOT" className="myLogo" />
          </Link>
          <nav>
            <ul id="menu-list">
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "active" : "inactive"
                  }
                  end
                  to="/"
                  id="homepage"
                >
                  Home
                </NavLink>
              </li>{" "}
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "active" : "inactive"
                  }
                  end
                  to="/about"
                  // onClick={unhighlightHomepage}
                >
                  About
                </NavLink>
              </li>{" "}
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "active" : "inactive"
                  }
                  end
                  to="/portfolio"
                  // onClick={unhighlightHomepage}
                >
                  Portfolio
                </NavLink>
              </li>{" "}
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "active" : "inactive"
                  }
                  end
                  to="/contact"
                  // onClick={unhighlightHomepage}
                >
                  Contact
                </NavLink>
              </li>
            </ul>
          </nav>
          <span className="reactIcon menu-icon" id="menu-icon">
            {Icons.menu}
          </span>
          {theme === "light" ? (
            <span className="reactIcon theme" id="darkTheme-icon">
              {Icons.moon}
            </span>
          ) : (
            <span className="reactIcon theme" id="darkTheme-icon">
              {Icons.sun}
            </span>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
