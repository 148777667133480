const Images = {
  // General
  darkLogo: require("../assets/img/darkLogo.png"),
  lightLogo: require("../assets/img/lightLogo.png"),

  // Flags
  ukFlag: require("../assets/img/languages/en.png"),
  frFlag: require("../assets/img/languages/fr.png"),

  // Skills
  html: require("../assets/img/skills/html-logo.png"),
  css: require("../assets/img/skills/css-logo.png"),
  javascript: require("../assets/img/skills/javascript-logo.png"),
  php: require("../assets/img/skills/php-logo.png"),
  bootstrap: require("../assets/img/skills/bootstrap-logo.png"),
  nodejs: require("../assets/img/skills/nodejs-logo.png"),
  express: require("../assets/img/skills/express-logo.png"),
  electron: require("../assets/img/skills/electron-logo.png"),
  reactjs: require("../assets/img/skills/reactjs-logo.png"),
  reactNative: require("../assets/img/skills/reactNative-logo.png"),
  java: require("../assets/img/skills/java-logo.png"),
  cs: require("../assets/img/skills/cs-logo.png"),
  mysql: require("../assets/img/skills/mysql-logo.png"),
  sqlite: require("../assets/img/skills/sqlite-logo.png"),
  mongodb: require("../assets/img/skills/mongodb-logo.png"),
  firebase: require("../assets/img/skills/firebase-logo.png"),
  scrum: require("../assets/img/skills/scrum-logo.png"),
  git: require("../assets/img/skills/git-logo.png"),
  github: require("../assets/img/skills/github-logo.png"),
  postman: require("../assets/img/skills/postman-logo.png"),
  uml: require("../assets/img/skills/uml-logo.png"),
  androidStudio: require("../assets/img/skills/androidStudio-logo.png"),
  photoshop: require("../assets/img/skills/photoshop-logo.png"),
  illustrator: require("../assets/img/skills/illustrator-logo.png"),
  xd: require("../assets/img/skills/xd-logo.png"),
  figma: require("../assets/img/skills/figma-logo.png"),
  latex: require("../assets/img/skills/latex-logo.png"),

  // Experience
  whitecape: require("../assets/img/experience/whitecape-logo.png"),
  discovery: require("../assets/img/experience/discovery-logo.png"),
  mediavision: require("../assets/img/experience/mediavision-logo.png"),
  eventus: require("../assets/img/experience/eventus-logo.png"),

  // Hobbies
  traveling: require("../assets/img/hobbies/traveling-icon.png"),
  camping: require("../assets/img/hobbies/camping-icon.png"),
  football: require("../assets/img/hobbies/football-icon.png"),
  fitness: require("../assets/img/hobbies/fitness-icon.png"),
  swimming: require("../assets/img/hobbies/swimming-icon.png"),
  gaming: require("../assets/img/hobbies/gaming-icon.png"),

  // Certificates
  certificate: require("../assets/img/certificates/certificate-icon.png"),
  starredCertificate: require("../assets/img/certificates/starredCertificate-icon.png"),

  // Contacts
  githubb: require("../assets/img/socialNetworks/github-icon.png"),
  stackOverflow: require("../assets/img/socialNetworks/stackOverflow-icon.png"),
  linkedin: require("../assets/img/socialNetworks/linkedin-icon.png"),
  credly: require("../assets/img/socialNetworks/credly-icon.png"),
  paypal: require("../assets/img/socialNetworks/paypal-icon.png"),
  whatsapp: require("../assets/img/socialNetworks/whatsapp-icon.png"),
  instagram: require("../assets/img/socialNetworks/instagram-icon.png"),
  bakchich: require("../assets/img/socialNetworks/bakchich-icon.png"),
  youtube: require("../assets/img/socialNetworks/youtube-icon.png"),
  discord: require("../assets/img/socialNetworks/discord-icon.png"),
  buyMeaCoffee: require("../assets/img/socialNetworks/buyMeaCoffee-icon.png"),

  // Not Found
  darkSmileImage: require("../assets/img/ErrorImage-dark.png"),
  lightSmileImage: require("../assets/img/ErrorImage-light.png"),
};

export default Images;
